<template>
    <!-- Page Content -->
    <div class="container" id="container">
      <div class="col-lg-12 mb-12 mt-5">
        <p class="text-left">
          応募を完了しました。<br><br>
          登録完了メールをお送りしておりますのでご確認ください。応募の際に同意いただいた同意書、説明動画を補足する同意説明書を添付しております。<br><br>
          もし同意を撤回されたい場合は、お手数ですがその旨を以下のメール宛にお送りください。<br><br>
          obo@ilt.com<br><br>
          研究対象者に選ばれた方は近日中に登録メールアドレスにご連絡致します。<br><br>
          なお、応募者あるいは、研究対象者に選定された方への謝金は調査完了後、随時ご自宅に送付されます。<br><br>
          有難うございました。
        </p>
      </div>
    </div>
</template>
<script>
import { Mixin } from '@/mixin.js'

export default {
  mixins: [Mixin],
  data() {
    return {
    }
  },
}
</script>
<style scoped>
div#wallet{
  margin-top: 30px;
}
table{
  width: 40%;
  margin: 0 auto;
}
th{
  background-color: #007db9;
  color: #fff;
}
th, td{
  padding: 5px;
  border: thin solid rgba(0, 0, 0, 0.12);
}
#detail-btn button{
  cursor: pointer;
}
td {
  font-size:small;
}
</style>
